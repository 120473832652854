import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.css']
})
export class InterviewComponent implements OnInit {


  


  entity = {
    entity_id: 1,
    name: '1863 VENTURES',
    admin: 'Emmanuel Kaska',
    username: '',
    password: '',
    cohorts: [{
      cohort_id: 1,
      start_date: 124 - 11 - 241,
      end_date: 123 - 14 - 142,
      name: 'AWESOME',
      companies: [{
        company_id: 123,
        name: 'asdf',
        founders: [{
          name: '',
          title: '',
        },
        {
          name: '',
          title: '',
        }
        ],
        business_type: '',
        NoOfEmployees: 1,
        Location: '',
        Username: '',
        Password: ''
      }]
    },
    {
      cohort_id: 2,
      start_date: 1231,
      end_date: 12312,
      name: 'SECOND',
      companies: [{
        company_id: 123,
        name: 'asdf',
        founders: [{
          name: '',
          title: '',
        },
        {
          name: 'company2',
          title: '',
        }
        ],
        business_type: '',
        NoOfEmployees: 1,
        Location: '',
        Username: '',
        Password: ''
      }]
    }
    ]
  }
  
  constructor() { }

  ngOnInit() {
  }

}
