import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Deck } from '../../car';

import { AngularFirestore, Query } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ShyftService {
  revenueMap = new Map();
  raiseAmount = new Map();
  dealers: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  spinner = false;
  filterValue: BehaviorSubject<any> = new BehaviorSubject<any>('');

  constructor(private http: HttpClient, private firestore: AngularFirestore) {

    
    this.revenueMap.set('A', '$0-$100,000');
    this.revenueMap.set('B', '$100k-500k');

    this.revenueMap.set('C', '$500k-10M');
    this.revenueMap.set('D', '$10M-$50M');
    this.revenueMap.set('E',  '$50M+');



  
    this.raiseAmount.set('A', '$0-$500,000');
    this.raiseAmount.set('B', '$500k-3.5M');
    this.raiseAmount.set('C', '$3.5M-10M');
    this.raiseAmount.set('D', '$10M-$50M');
    this.raiseAmount.set('E', '$50M+<');


   }

  /*
              return this.firestore.collection(`decks`, ref => ref.where('investmentStage', '==', 'pre-seed')
                                                             .where('hqLocation', '==', 'USA'))
 */

  filterDealers(): Observable<any> {
    return this.filterValue
      .pipe(
        switchMap(value => {

          if (value) {
            return this.firestore.collection(`decks`, ref => this.createWhereClause(value, ref))
              .snapshotChanges()
              .pipe(
                tap(val => console.log('BEFORE MAP:', val)),
                map((items: any[]) => {
                  return items.map(e => {
                    return this.mapFirebaseStructure2JSON(e)
                  });
                }),
                tap(val => console.log('AFTER MAP:', val)),
              )
          }
          else {
            return this.firestore.collection('decks')
              .snapshotChanges()
              .pipe(
                tap(val => console.log('BEFORE MAP:', val)),
                map((items: any[]) => {
                  return items.map(e => {
                    return this.mapFirebaseStructure2JSON(e)
                  });
                }),
                tap(val => console.log('AFTER MAP:', val)),
              )
          }
        }
        ));
  }

  createWhereClause(obj, ref){
    // tslint:disable-next-line: label-position
    let filteredQuery = null;
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
          let value = obj[key];
          if (value) {
            console.log(`key: ${key} ==  value: ${value}`)
            if(!filteredQuery) {
              filteredQuery = ref.where(key, '==', value);
            } else {
              filteredQuery = filteredQuery.where(key, '==', value)
            }
          }

      }
    }
    return filteredQuery;
  }

  mapFirebaseStructure2JSON(element) {
    return {
      id: element.payload.doc.id,
      ...element.payload.doc.data()
    } as Deck;
  }

}
