import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { stringify } from 'querystring';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.css']
})
export class HeroComponent implements OnInit {

  audience: any = null;
  
  entity = {
    entity_id: 1,
    name: '1863 VENTURES',
    admin: 'Emmanuel Kaska',
    username: '',
    password: '',
    cohorts: [{
      cohort_id: 1,
      start_date: 124 - 11 - 241,
      end_date: 123 - 14 - 142,
      name: 'AWESOME',
      companies: [{
        company_id: 123,
        name: 'Lab 1',
        founders: [{
          name: '',
          title: '',
        },
        {
          name: '',
          title: '',
        }
        ],
        business_type: 'Retail',
        NoOfEmployees: 41,
        Location: 'NY, NY',
        Username: '',
        Password: ''
      },
      {
        company_id: 123,
        name: 'Dyess Group',
        founders: [{
          name: '',
          title: '',
        },
        {
          name: '',
          title: '',
        }
        ],
        business_type: 'Services',
        NoOfEmployees: 12,
        Location: 'Washington, DC',
        Username: '',
        Password: ''
      },
      {
        company_id: 123,
        name: 'Kivvik',
        founders: [{
          name: '',
          title: '',
        },
        {
          name: '',
          title: '',
        }
        ],
        business_type: 'SaaS',
        NoOfEmployees: 2,
        Location: 'LA, CA',
        Username: '',
        Password: ''
      },
      {
        company_id: 123,
        name: 'Doctor Up',
        founders: [{
          name: '',
          title: '',
        },
        {
          name: '',
          title: '',
        }
        ],
        business_type: 'Healthcare',
        NoOfEmployees: 4,
        Location: 'College Park, MD',
        Username: '',
        Password: ''
      }]
    },
    {
      cohort_id: 2,
      start_date: 1231,
      end_date: 12312,
      name: 'SECOND',
      companies: [{
        company_id: 123,
        name: 'asdf',
        founders: [{
          name: '',
          title: '',
        },
        {
          name: 'company2',
          title: '',
        }
        ],
        business_type: '',
        NoOfEmployees: 1,
        Location: '',
        Username: '',
        Password: ''
      }]
    }
    ]
  }

  constructor(private router: ActivatedRoute) { }

  ngOnInit() {
    this.router.queryParams.subscribe(params => {
      console.log(params);
      stringify(params[0]);
      console.log(params[0]);


      this.audience = this.entity.cohorts[0];

      // this.profileJson = this.params.filter(
      //   book => book.store_id === this.store.id);
    });
  }

}
