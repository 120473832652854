import { Component, OnInit } from '@angular/core';
import { ShyftService } from '../core/services/shyft-services/shyft.service';

@Component({
  selector: 'app-shyft-view',
  templateUrl: './shyft-view.component.html',
  styleUrls: ['./shyft-view.component.scss']
})
export class ShyftViewComponent implements OnInit {

  progress = [{
    id: "stratC-001",
    title: "Product",
    link: "finance",
    bg: "product",
    desc: "Refine your the value you provide",
    subcat: [{
      
    }]
  },
  {
    id: "stratC-002",
    title: "Fundraising",
    link: "strategy",
    bg: "fundraising"
  },
  {
    title: "Operations",
    link: "strategy",
    bg: "operations"
  }
]

  cards = [{
    id: "stratC-001",
    title: "Product",
    link: "finance",
    bg: "product",
    desc: "Refine your the value you provide",
    subcat: [{
      
    }]
  },
  {
    id: "stratC-002",
    title: "Fundraising",
    link: "strategy",
    bg: "fundraising"
  },
  {
    title: "Finance & Accounting",
    link: "strategy",
    bg: "finance"
  },
  {
    title: "Marketing",
    link: "strategy",
    bg: "marketing"
  },
  {
    title: "Sales",
    link: "strategy",
    bg: "sales"
  },
  {
    title: "Operations",
    link: "strategy",
    bg: "operations"
  },
  {
    title: "Customer Success",
    link: "strategy"
  },
]


  selectedView = this.changeView();

  constructor(public shyftService: ShyftService) { }

  ngOnInit(): void {

  }

  changeView() {
    return this.selectedView === 'grid' ? this.selectedView = 'list' : this.selectedView = 'grid';
  }
}
