import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { stringify } from 'querystring';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profileJson: any = null;



  entity = {
    entity_id: 1,
    name: '1863 VENTURES',
    admin: 'Emmanuel Kaska',
    username: '',
    password: '',
    cohorts: [{
      cohort_id: 1,
      start_date: 124 - 11 - 241,
      end_date: 123 - 14 - 142,
      name: 'AWESOME',
      companies: [{
        company_id: 123,
        name: 'asdf',
        founders: [{
          name: '',
          title: '',
        },
        {
          name: '',
          title: '',
        }
        ],
        business_type: '',
        NoOfEmployees: 1,
        Location: '',
        Username: '',
        Password: ''
      }]
    },
    {
      start_date: 1231,
      end_date: 12312,
      name: 'SECOND',
      companies: [{}]
    }
    ]
  }

  strategyActivity = [{
    entity_id: 2,
    cohort_id: 1,
    company_id: 2,
    strat_id: 2,
  }]

  companyProcessActivity = [{
    company_id: 1,
    strat_id: 2,
    process_id: 2,
    start_time: 123,
    end_time: null,
    isComplete: false,
  }]

  companyProcessTaskActivity = [{
    compProcessActivity_id: 1,
    task: [{
      task_id: 1,
      isComplete: true,
    },
    {
      task_id: 2,
      isComplete: true,
    },
    {
      task_id: 3,
      isComplete: true,
    }
    ]
  }]

  //When all tasks are done its end time

  cards = [{
    strat_id: "stratC-001",
    title: "Product",
    link: "finance",
    bg: "product",
    desc: "Refine your the value you provide",
    processes: [{
      process_id: 0,
      subTitle: "Validate Product",
      cardContent: "Assess Product Validity",
      tasks: [{
        title: 'Task 1',
        description: 'This is task 1 for all you people'
      },
      {
        title: 'tatking ',
        description: 'tatstst'
      }]
    },
    {
      subTitle: "Process 1",
      cardContent: "Analyze competitor affiliate program"
    },
    {
      subTitle: "Process 2",
      cardContent: "Determine Program Approach"
    },
    {
      subTitle: "Process 3",
      cardContent: "Project Financial Impact of Project"
    },
    {
      subTitle: "Process 4",
      cardContent: "Determine Affiliate Payout Plan "
    },
    {
      subTitle: "Action Plan",
      cardContent: "Assess Product Validity"
    },
    ]
  },
  {
    id: "stratC-002",
    title: "Fundraising",
    link: "strategy",
    bg: "fundraising"
  },
  {
    title: "Finance & Accounting",
    link: "strategy",
    bg: "finance"
  },
  {
    title: "Marketing",
    link: "strategy",
    bg: "marketing"
  },
  {
    title: "Sales",
    link: "strategy",
    bg: "sales"
  },
  {
    title: "Operations",
    link: "strategy",
    bg: "operations"
  },
  {
    title: "Customer Success",
    link: "strategy"
  },
  ]
  params: any;


  constructor(public auth: AuthService, private router: ActivatedRoute) { }

  ngOnInit() {
    this.auth.userProfile$.subscribe(
      profile => this.profileJson = JSON.stringify(profile, null, 2)
    );

    this.router.queryParams.subscribe(params => {
      console.log(params);
      stringify(params[0]);
      console.log(params[0]);


      this.profileJson = this.cards.filter(i => i.link === params[0])[0];

      console.log(this.profileJson);
      // this.profileJson = this.params.filter(
      //   book => book.store_id === this.store.id);
    });
  }

}
