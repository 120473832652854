import { NgModule } from '@angular/core';
// PrimeNg components
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

const PrimeComponents = [
    CardModule,
    ButtonModule,
    ProgressSpinnerModule
];

@NgModule({
    imports: [PrimeComponents],
    exports: [PrimeComponents]
  })
  export class PrimeNgModule { }
