// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyD-fFgCdnOYmZMPoZAbYP8qSxxsZ-HzMCo",
    authDomain: "dyess-admin.firebaseapp.com",
    databaseURL: "https://dyess-admin.firebaseio.com",
    projectId: "dyess-admin",
    storageBucket: "dyess-admin.appspot.com",
    messagingSenderId: "162946468358",
    appId: "1:162946468358:web:8a1954ae61e15889799187",
    measurementId: "G-XHFLTLQ3PC"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
