import { Component, OnInit, Input, Renderer2, ElementRef } from '@angular/core';
import { ShyftService } from 'src/app/core/services/shyft-services/shyft.service';
@Component({
  selector: 'app-shyft-card',
  templateUrl: './shyft-card.component.html',
  styleUrls: ['./shyft-card.component.scss']
})
export class ShyftCardComponent implements OnInit {
pdfFilePath: string;

@Input()
set selectedView(selectedView: any) {
  this.onClick(selectedView);
}

  constructor(public shyftService: ShyftService,
              private renderer: Renderer2,
              private el: ElementRef) {
                this.shyftService.filterDealers().subscribe(value => {
                  this.shyftService.dealers.next(value);
                });
              }

  ngOnInit(): void {
  }

  onClick(selectedView) {
    this.shyftService.spinner = true;
    return selectedView === 'grid' ? this.renderer.addClass(this.el.nativeElement.children[0], 'p-grid')
    : this.renderer.removeClass(this.el.nativeElement.children[0], 'p-grid');
  }


}
