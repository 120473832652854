import { Component, OnInit, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Deck } from 'src/app/core/car';

@Component({
  selector: 'app-deal-card',
  templateUrl: './deal-card.component.html',
  styleUrls: ['./deal-card.component.scss']
})
export class DealCardComponent implements OnInit {
  @Input() dealer: Deck = null;
  pdfFilePath = '';
  constructor(private fireStorage: AngularFireStorage) { }

  ngOnInit() {
  }

  downloadFile(filePath) {
    const storageRef = this.fireStorage.ref("dyess-admin.appspot.com");
    this.fireStorage.ref( filePath).getDownloadURL().subscribe(value => {
      this.pdfFilePath = value;
    });
  }


  log(dealer) {
    console.log('logged dealer', dealer);
  }

}
