import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
taskList: any = null;


entity = {
  entity_id: 1,
  name: '1863 VENTURES',
  admin: 'Emmanuel Kaska',
  username: '',
  password: '',
  cohorts: [{
    cohort_id: 1,
    start_date: 124 - 11 - 241,
    end_date: 123 - 14 - 142,
    name: 'AWESOME',
    companies: [{
      company_id: 123,
      name: 'asdf',
      founders: [{
        name: '',
        title: '',
      },
      {
        name: '',
        title: '',
      }
      ],
      business_type: '',
      NoOfEmployees: 1,
      Location: '',
      Username: '',
      Password: ''
    }]
  },
  {
    start_date: 1231,
    end_date: 12312,
    name: 'SECOND',
    companies: [{}]
  }
  ]
}

strategyActivity = [{
  entity_id: 2,
  cohort_id: 1,
  company_id: 2,
  strat_id: 2,
}]

companyProcessActivity = [{
  company_id: 1,
  strat_id: 2,
  process_id: 2,
  start_time: 123,
  end_time: null,
  isComplete: false,
}]

companyProcessTaskActivity = [{
  compProcessActivity_id: 1,
  task: [{
    task_id: 1,
    isComplete: true,
  },
  {
    task_id: 2,
    isComplete: true,
  },
  {
    task_id: 3,
    isComplete: true,
  }
  ]
}]

//When all tasks are done its end time

cards = [{
  strat_id: "stratC-001",
  title: "Product",
  link: "finance",
  bg: "product",
  desc: "Refine your the value you provide",
  processes: [{
    process_id: 0,
    subTitle: "Validate Product",
    cardContent: "Assess Product Validity",
    tasks: [{
      title: 'Develop a Budget',
      short_desc: 'This is task 1 for all you people', 
      long_desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', 
      img: '',
      resources: [{
        title: '',
        link: '',
        type: ''
      }]
    },
    {
      title: 'Balance your Accounts ',
      short_desc: 'This is task 1 for all you people', 
      long_desc: 'testing 123',
      img: '', 
    },
    {
      title: 'Set Balance Checks ',
      short_desc: 'This is task 1 for all you people', 
      long_desc: 'testing 123',
      img: '', 
    },
    {
      title: 'Validate Assumptions ',
      short_desc: 'This is task 1 for all you people', 
      long_desc: 'testing 123',
      img: '', 
    },
    {
      title: 'Show your Finance Friend ',
      short_desc: 'This is task 1 for all you people', 
      long_desc: 'testing 123',
      img: '', 
    },
    {
      title: 'Model it all out ',
      short_desc: 'This is task 1 for all you people', 
      long_desc: 'testing 123',
      img: '', 
    }
  ]
  },
  {
    subTitle: "Process 1",
    cardContent: "Analyze competitor affiliate program"
  },
  {
    subTitle: "Process 2",
    cardContent: "Determine Program Approach"
  },
  {
    subTitle: "Process 3",
    cardContent: "Project Financial Impact of Project"
  },
  {
    subTitle: "Process 4",
    cardContent: "Determine Affiliate Payout Plan "
  },
  {
    subTitle: "Action Plan",
    cardContent: "Assess Product Validity"
  },
  ]
},
{
  id: "stratC-002",
  title: "Fundraising",
  link: "strategy",
  bg: "fundraising"
},
{
  title: "Finance & Accounting",
  link: "strategy",
  bg: "finance"
},
{
  title: "Marketing",
  link: "strategy",
  bg: "marketing"
},
{
  title: "Sales",
  link: "strategy",
  bg: "sales"
},
{
  title: "Operations",
  link: "strategy",
  bg: "operations"
},
{
  title: "Customer Success",
  link: "strategy"
},
]
  constructor( private router: ActivatedRoute) { }

  ngOnInit() {

    this.router.queryParams.subscribe(params => {
      console.log(params);
      console.log(params[0]);
      const process = this.cards[0].processes[0];
      this.taskList = process;

      console.log(this.taskList);
      // this.profileJson = this.params.filter(
      //   book => book.store_id === this.store.id);
    });
  }

}
