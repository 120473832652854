import { iDeck } from './models/deck.model';

export class Deck implements iDeck {
    id: string;
    investmentStage: string;
    description?: string;
    filePath?: string;
    industry?: string;
    dealDesc?: string;
    name?: string;
    sector?: string;
    hqLocation?: string;
    businessModel?: string;
    revenue?: string;
    raiseAmount?: string;

    constructor(id: string = '', investmentStage: string = '', filePath: string = '', description: string = '', industry: string = '', dealDesc: string = '', name: string = '') {
        this.id = id;
        this.investmentStage = investmentStage;
        this.description = description;
        this.filePath = filePath;
        this.industry = industry;
        this.dealDesc = dealDesc;
        this.name = name;
        this.revenue = '';
        this.raiseAmount = '';
    }
}


