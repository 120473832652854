import { Component, OnInit } from '@angular/core';
import { ShyftService } from 'src/app/core/services/shyft-services/shyft.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { iDeck } from 'src/app/core/models/deck.model';
import { Deck } from 'src/app/core/car';

@Component({
  selector: 'app-shyft-search',
  templateUrl: './shyft-search.component.html',
  styleUrls: ['./shyft-search.component.scss']
})
export class ShyftSearchComponent implements OnInit {

searchValue = new FormControl('');

  public searchDeck: iDeck = null;

  constructor(private shyftService: ShyftService) {
    this.searchDeck = new Deck();
   }
  ngOnInit(): void {
  }

  filterDealers(value: string) {
    this.searchDeck.investmentStage = value;
    console.log('searchValue', this.searchDeck);
    if(!this.isFilterFilled()) {
      this.shyftService.filterValue.next('');
      return;
    }
    this.shyftService.filterValue.next(this.searchDeck);
    console.log("get Enum by Index", this.searchDeck.revenue[this.searchDeck.revenue]);
  }

  isFilterFilled() {
    return !!this.searchDeck.investmentStage
           || !!this.searchDeck.businessModel
           || !!this.searchDeck.hqLocation
           || !!this.searchDeck.industry
           || !!this.searchDeck.sector
           || !!this.searchDeck.raiseAmount
           || !!this.searchDeck.revenue;
  }
}
